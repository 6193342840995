.swiper-slide{
    display: flex;
    align-items: center;
}
.container__proyectos{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: 730px;
    // @media (max-width: 1040px) {
    //     height: 150vh;
    // }
    img{
        width: 400px;
        @media (min-width: 1600px) {
            width: 550px;
        }
        @media (max-width: 900px) {
            width: 80%;
        }
    }
    &_card{
        width: 100%;
        .swiper-container{
            width: 100%;
        }
        .card{
            // width: 300px;
            // height:400px;
            width: 85%;
            height: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @media (min-width: 1600px) {
                // width: 400px;
                // height:522px;
            }
            @media (max-width: 900px) {
                width: 100%;
                height: 70%;
            }
            .fondo{
                width: 85%;
                height: 70%;
                object-fit: cover;
                object-position: top;
                @media (min-width: 1600px) {
                    width: 65%;
                    height: 70%;
                }
                @media (max-width: 900px) {
                    width: 70%;
                    // height: 100%;
                }
                @media (max-width: 550px) {
                    width: 70%;
                 height: 100%;
                }
            }
            &__top{
                width: 85%;
                background-color: #0a86bf;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 0;
                @media (min-width: 1600px) {
                    width: 65%;
                }
                @media (max-width: 900px) {
                    width: 70%;
                }
                img{
                    width: 100px;
                }
            }
            &__bottom{
                width: 85%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media (min-width: 1600px) {
                    width: 65%;
                }
                @media (max-width: 900px) {
                    width: 70%;
                }
                &_precio{
                    display: flex;
                    flex-direction: column;
                    padding: 5px 0;
                    p{
                        font-size: 13px;
                        &:nth-child(2){
                            font-size: 20px;
                        }
                    }
                }
                a{
                    background-color: #0a86bf;
                    color: white;
                    // padding: 5px 10px;
                    border: 1px solid transparent;
                    border-radius: 15px;
                    text-decoration: none;
                    transition: background-color 1s ease;
                    justify-content: center;
                    width: 80px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    @media (min-width: 1600px) {
                        width: 100px;
                        // height: 35px;
                    }
                    @media (max-width: 900px) {
                        font-size: 15px;
                        padding-top: 4px;
                    }
                    &:hover{
                        border: 1px solid #0a86bf;
                        background-color: #fff;
                        color: #0a86bf;
                    }
                }
            }
        }
    }
}