// @import './componentes/Header/Header.scss';


@font-face {
    font-family: "gill sans bold";
    src: url("/fonts/GillSans/Gill\ Sans\ Bold.otf");
}
@font-face {
    font-family: "gill sans medium";
    src: url("fonts/GillSans/Gill\ Sans\ Medium.otf");
}
@font-face {
    font-family: "gill sans light";
    src: url("fonts/GillSans/Gill\ Sans\ Light.otf");
}
@font-face {
    font-family: "monserrat";
    src: url("fonts/montserrat/Montserrat-Black.otf");
}
.floating-chat-btn{
    width: 50px;
    height: 50px;
    background: #D52B1E;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 50%;
    border-color: #ffffff;
    border: 2px;
    border-style: solid;
    color: #ffffff;
    font-size: 30px;
    position: fixed;
    right: 20px;
    bottom: 80px;
    z-index: 2000;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    font-size: 16px;
    font-family: "gill sans medium";
    @media (min-width: 1600px) {
        font-size: 18px;
    }
}

.show-enter{
    opacity: 0;
    transform: scale(0.9);
}
.show-enter-active {
    opacity: 0.5;
    transform: translateX(0);
    transition: opacity 0.1s, transform 0.5s;
}
.show-enter-done  {
    opacity: 1;
}
.show-exit {
    opacity: 1;
}
.show-exit-active {
    opacity: 0.5;
    transform: scale(0.9);
    transition: opacity 0.1s, transform 0.5s;
}
.show-exit-done {
    opacity: 0;
}


.deslizar-enter{
    opacity: 0;
    // transform: scale(0.9);
    transform: translateY(-800px);
}
.deslizar-enter-active {
    opacity: 0.1;
    // transform: translateX(0);
    // transform: translateY(0);
    transition: opacity 1s, transform 1s;
}
.deslizar-enter-done  {
    opacity: 1;
    transform: translateY(0);
}
.deslizar-exit {
    opacity: 0;
}
.deslizar-exit-active {
    opacity: 0.1;
    // transform: scale(0.9);
    transform: translateY(-800px);
    transition: opacity 1s, transform 1s;
}
.deslizar-exit-done {
    opacity: 0;
}
.ocultar{
   display: none;
}
.App{
    width: 100%;
    position: relative;
    overflow: hidden;
}
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #f20004;
    z-index: 999;
}
#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #fff;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #f9b833;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}
#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #fff;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.swiper-button-next, .swiper-button-prev{
    color: white !important;
}
.whatsapp{
    width: 60px;
    height: 60px;
    position: fixed;
    bottom: 5%;
    right: 20px;
    border-radius: 50%;
    z-index: 99;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 1s ease;
    @media (max-width:550px) {
        width: 50px;
        height: 50px;
        bottom: 20px;
    }
    a{
        // width: 60%;
        img{
            width: 100%;
        }
    }
    &:hover{
        transform: scale(1.2);

    }
}
.swal-overlay{
    z-index: 999999 !important;
}

a{
    cursor: pointer;
}