
.container__footer{
    width: 100%;
    background-color: #f20004;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px 20px;
    @media (max-width: 900px) {
        flex-wrap: wrap;
        align-items: flex-start;
    }
    img{
        width: 200px;
        @media (max-width: 450px) {
            width: 135px;
            padding: 10px 0;
        }
    }
    p{
        color: white;
        font-size: 12px;
        &:nth-child(3){
            cursor: pointer;
        }
        @media (max-width: 900px) {
            // text-align: center;
            margin: 10px 0;
            &:nth-child(3){
                order: 5;
                width: 30%;
                cursor: pointer;
                font-size: 11px;
            }
            &:nth-child(4){
               order: 4;
               width: 70%;
               font-size: 11px;
            }
        }
    }
    div{
        width: 15%;
        @media (max-width: 900px) {
            width: 100%;
        }

        &:nth-child(2){
            @media (max-width: 900px) {
                order: 2;
                p{
                    &:nth-child(1){
                        // font-style: italic;
                        font-family: "gill sans bold";
                    }
                    &:nth-child(2){
                        padding-left: 20px;
                    }

                }
            }
        }
        &:nth-child(5){
            @media (max-width: 900px) {
                order: 3;
                p{
                    &:nth-child(1){
                        // font-style: italic;
                        font-family: "gill sans bold";
                    }
                    &:nth-child(2){
                        padding-left: 20px;
                    }

                }
            }
            p{
                text-align: right;
                @media (max-width: 900px) {
                    text-align: left;
                }
            }
        }
    }
}