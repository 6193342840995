.container__modal{
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: #394336d3;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    &.background{
        background-color: white;
    }
    &.sobreponer{
        z-index: 999999;
    }
    .close{
        position: absolute;
        right: 10%;
        top: 5%;
        font-size: 40px;
        color: white;
        cursor: pointer;
        z-index: 99;
    }
    &_card{
        width: 90%;
        height: 85%;

        @media (min-height: 1600px) {
            width: 90%;
            height: 80%;
        }
    }
}