.main-cover {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin-bottom: auto;
  z-index: 10;
  position: relative;

  &__phrase-img {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    padding-bottom: 60px;
    position: relative;
    @media (max-width: 552px) {
      position: absolute;
      top: 50px;
      left: -30px;
    }
    img {
      max-width: 100%;
      max-height: 100%;
      width: 500px;
      object-fit: contain;
      object-position: center;
      @media (max-width: 552px) {
        width: 280px;
      }
    }
    @media (max-width: 1199px) {
      width: 50%;
    }
    @media (max-width: 552px) {
      width: 40%;
    }
  }

  &__project-img {
    width: 40%;
    display: flex;
    justify-content: start;

    @media (max-width: 1199px) {
      width: 50%;
    }

    img {
      height: 100%;
      margin-left: auto;
      margin-bottom: -30px;
      @media (max-width: 1199px) {
        height: 500px;
      }
    }
    @media (max-width: 552px) {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }
  @media (max-width: 552px) {
    width: 90%;
  }
}
