.swiper-container {
  height: 100%;
  width: 70%;
  @media (min-width: 1600px) {
    width: 75%;
  }
  @media (max-width: 550px) {
    width: 100%;
  }
}

.container__principal {
  width: 100%;
  height: 100vh;
  background-color: #f20004;
  display: flex;
  align-items: flex-end;
  min-height: 650px;
  position: relative;
  @media (max-width: 900px) {
    min-height: 740px;
    justify-content: center;
  }
  .bottom__triangulo1 {
    position: absolute;
    width: 100%;
    z-index: 88;
  }
  &_back {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      @media (max-width: 900px) {
        object-fit: cover;
      }
    }
  }
  &_slogan {
    @media (max-width: 900px) {
      width: 100%;
      height: 20%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
    .slogan {
      width: 100px;
      position: absolute;
      padding-bottom: 80px;
      // bottom: 25px;
      left: 5px;
      @media (min-width: 1600px) {
        width: 135px;
        padding-bottom: 180px;
      }
      @media (max-width: 900px) {
        // bottom: 14%;
        width: 200px;
        padding-bottom: 0;
        position: relative;
      }
      @media (max-width: 520px) {
        width: 150px;
      }
      // @media (max-width: 520px) {
      //     right: 10px;
      //     left: auto;
      //     padding-bottom: 45px;
      //     width: 100px;
      // }
    }
  }
  .cuadros {
    width: 40px;
    position: absolute;
    top: 40%;
    @media (max-width: 900px) {
      width: 20px;
      position: absolute;
      top: 10%;
      left: 0;
    }
  }
  .degradado {
    position: absolute;
    width: 100%;
  }
  .swiper-slide {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    @media (max-width: 900px) {
      justify-content: flex-start;
    }
  }
  &_carousel {
    width: 55%;
    height: 100%;
    position: absolute;
    right: 0;
    // top: 0;
    @media (max-width: 900px) {
      height: 40%;
      width: 50%;
      top: 73px;
      left: 10px;
    }
    .raya {
      display: none;
      @media (max-width: 900px) {
        z-index: 99;
        display: block;
        position: absolute;
        width: 335px;
        bottom: -20px;
        img {
          width: 100%;
        }
      }
    }
    &__slider {
      width: 90%;
      height: 93%;
      position: absolute;
      display: flex;
      align-items: flex-end;
      // top: 25%;
      @media (min-width: 1600px) {
        width: 93%;
      }
      // @media (max-width: 1400px) {
      //     height: 60%;

      // }
      @media (max-width: 900px) {
        // height: 90%;
        height: 100%;
        width: 100%;
      }
      @media (max-width: 590px) {
        height: 100%;
        width: 100%;
      }
      img {
        width: 100%;
        height: 80%;
        object-fit: contain;
        object-position: bottom;
        @media (max-width: 900px) {
          width: 100%;
          height: 85%;
          object-fit: cover;
          object-position: top;
        }
        @media (max-width: 520px) {
          width: 100%;
          height: 70%;
          object-fit: cover;
          object-position: top;
        }
      }
    }
  }
  &_frase {
    width: 45%;
    height: 90%;
    position: absolute;
    // top: 0;
    left: 5%;
    display: flex;
    flex-direction: column;
    // padding-top: 80px;
    justify-content: space-evenly;
    @media (max-width: 900px) {
      height: 50%;
      padding: 0 15px;
      width: 100%;
      left: 0;
      align-items: flex-end;
      justify-content: space-around;
      top: 73px;
      min-height: auto;
      min-height: 600px;
    }
    @media (max-width: 550px) {
      // padding-top: 110px;
      height: 58%;
      width: 100%;
      min-height: 530px;
    }
    &_top {
      display: flex;
      position: relative;
      @media (max-width: 900px) {
        width: 60%;
        height: 67%;
        justify-content: center;
        align-items: flex-end;
      }
      @media (max-width: 550px) {
        justify-content: center;
        height: 60%;
        width: 60%;
        max-height: 250px;
        align-items: flex-end;
      }
      .frase {
        width: 80%;
        max-width: 100%;
        margin: 0 auto;
        @media (max-width: 900px) {
          width: 80%;
          // margin-bottom: 50px;
        }
        @media (max-width: 550px) {
          width: 90%;
          // min-width: 202px;
          min-height: auto;
        }
      }

      //   Override de carrusel para frases
      .swiper-slide {
        align-items: center;
        justify-content: center;
      }

      .swiper-container {
        width: 90%;
        max-width: 100%;
        margin-right: auto;
      }
    }
    .propiedades {
      display: flex;
      align-items: end;
      justify-content: space-around;
      width: 80%;
      height: 10%;
      margin: 0 auto;
      @media (min-width: 1600px) {
        width: 65%;
      }
      @media (max-width: 900px) {
        width: 93%;
        height: 30%;
        align-items: center;
      }
      a {
        img {
          width: 70%;
          height: 100%;
          @media (min-width: 1600px) {
            width: 70%;
          }
        }
        // &:nth-child(3){
        //    img{
        //         width: 40px;
        //         @media (min-width: 1600px) {
        //             width: 60px;
        //         }
        //    }
        // }
      }
    }
  }
}
