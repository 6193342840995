
.container__conocenos{
    width: 100%;
    height: 90vh;
   
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    overflow: hidden;
    background-color:  #f20004;
    max-height: 728px;
    @media (max-width: 450px) {
        min-height: 680px;
    }
    .bottom__triangulo2{
        position: absolute;
        width: 100%;
        z-index: 88;
    }
    .degradado{
        position: absolute;
        width: 100%;
        // bottom: 25px;
        // @media (max-width: 900px) {
        //     bottom: 11%;
        // }
        // @media (max-width: 450px) {
        //     bottom: 13%;
        // }
    }
    &_back{
        width: 100%;
        height: 100%;
        img{
            width: 100%;
            height: 100%;
            @media (max-width: 900px) {
                object-fit: cover;
            }
        }
    }
    &_mapa{
        position: absolute;
        width: 58%;
        left: 0px;
        @media (max-width: 900px) {
            width: 72%;
        }
        @media (max-width: 550px) {
            width: 138%;
            bottom: -18px;
        }
    }
    &_top{
        width: 80%;
        display: flex;
        position: absolute;
        top: 10%;
        align-items: center;
        @media (min-width: 1600px) {
            width: 70%;
            top: 15%;
        }
        @media (max-width: 900px) {
            width: 95%;
            padding: 0 20px;
            align-items: flex-start;
        }
        @media (max-width: 550px) {
            flex-direction: column;
            width: 100%;
            top: 8%;
        }
        img{
            width: 400px;
            @media (min-width: 1600px) {
                width: 500px;
            }
            @media (max-width: 900px) {
                width: 300px;
            }
            @media (max-width: 550px) {
                width: 200px;
            }
        }
        p{
            color: white;
            font-size: 23px;
            @media (min-width: 1600px) {
               font-size: 25px;
            }
            @media (max-width: 550px) {
                font-size: 18px;
            }
            span{
                font-size: 25px;
                font-family: 'gill sans bold';
                @media (min-width: 1600px) {
                    font-size: 28px;
                }
                @media (max-width: 550px) {
                    font-size: 22px;
                }
            }
        }
    }
    &_bottom{
        width: 50%;
        height: 35%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        position: absolute;
        top: 50%;
        @media (max-width: 900px) {
            width: 85%;
            top: 30%;
        }
        @media (max-width: 550px) {
            width: 100%;
            top: 42%;
            height: 20%;
        }
        &__anios{
            width: 90%;
            display: flex;
            justify-content: space-between;
            @media (min-width: 1600px) {
                width: 60%;
            }
            @media (max-width: 900px) {
                width: 55%;
            }
            @media (max-width: 600px) {
                width: 65%;
            }
            img{
                width: 200px;
                @media (min-width: 1600px) {
                    width: 250px;
                }
                @media (max-width: 1024px) {
                    width: 45%;
                }
            }
        }
        a{
            background-color: white;
            color: #f20004;
            // padding: 5px 25px;
            border-radius: 15px;
            text-decoration: none;
            font-family: 'gill sans bold';
            transition: background-color 1s ease;
            justify-content: center;
            width: 130px;
            height: 30px;
            display: flex;
            align-items: center;
            @media (min-width: 1600px) {
                // padding: 10px 35px;
                width: 150px;
                height: 35px;
            }
            @media (max-width: 900px) {
                font-size: 15px;
                padding-top: 4px;
            }
            &:hover{
                background-color: #f9b833;
                color: #fff;
            }
        }
    }
}