.activo{
    transform: scale(1.1);
}
.contenedor__prototipos{
    width: 100%;
    height: 250px;
    background-color: #f20004;
    @media (min-width: 1600px) {
        height: 300px;
    }
        .card{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            @media (max-width: 550px) {
                width: 100%;
            }
            img{
                width: 85%;
                height: 80%;
                object-fit: contain;
                @media (max-width: 550px) {
                    width: 60%;
                }
                &:hover{
                    transform: scale(1.1);
                }
            }

        }
}