
.contenedor__popup{
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #50534fd3;
        .modal{
            width: 25%;
            height: 75%;
            position: relative;
            @media (max-width: 900px) {
                width: 55%;
                height: 60%;
            }
            @media (max-width: 520px) {
                width: 80%;
                height: 55%;
            }
            p{
                font-size: 30px;
                position: absolute;
                right: 4%;
                top: 1%;
                color: #f20004;
                cursor: pointer;
            }
            img{
                width: 100%;
                cursor: pointer;
            }
        }
}