
.contenedor_blog{
    width: 100%;
    //height: 100vh;
    min-height: 800px;
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width:1600px) {
        padding: 100px;
    }
    @media (max-width:1024px) {
        padding: 60px 20px;
    }
    &_flex{
        padding: 50px 0;
        // display: flex;
        // justify-content: space-between;
        // flex-wrap: wrap;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 50px;
        //justify-items: center;
        @media (max-width:1024px) {
            grid-template-columns: 1fr 1fr;
            column-gap: 30px;
        }
        @media (max-width:550px) {
            grid-template-columns: 1fr;
            column-gap: 30px;
        }
        a{
            text-decoration: none;
            color: gray  ;
            display: flex;
        }
    }
}
// .css-1ri6ub7-MuiPaper-root-MuiCard-root{
//     //width: 30%;
//     margin: 0 20px 20px;
//     @media (min-width:1600px) {
//         //width: 30%;
//     }
// }
.MuiPaper-root{
    margin: 0 20px 20px;
    height: fit-content;
}
// .css-46bh2p-MuiCardContent-root{
//     min-height: 112px;
// }
.MuiCardContent-root{
    min-height: 112px;
}
.react-share__ShareButton{
    display: flex;
    align-items: center;
}