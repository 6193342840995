
.container__contactanos{
    background-color: #393939;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 15px 20px;
    position: relative;
    img{
        cursor: pointer;
        width: 15%;
        @media (min-width: 1600px) {
            width: 12%;
        }
        @media (max-width: 550px) {
            width: 30%;
        }
    }
    a{
        display: flex;
        justify-content: center;
        img{
            width: 80%;
            // width: 160px !important;
            @media (min-width: 1600px) {
                width: 75%;
            }
            @media (max-width: 550px) {
                width: 80%;
            }
        }
        &:nth-child(3){
            img{
                width: 75%;
                @media (min-width: 1600px) {
                    width: 75%;
                }
                // width: 100px !important;
                // @media (min-width: 1600px) {
                //     width: 115px !important;
                // }
                // @media (max-width: 450px) {
                //     width: 50px !important;
                // }
            }
        }
    }
    .linea{
        background-color: #f9b833;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 5px;
    }
}