.background__red{
    background-color: #f20004;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0;
    margin-bottom: 20px;
    a{
        img{
            width: 140px;
        }
        &:nth-child(2){
            img{
                width: 125px;
            }
        }
    }
}
.container__ubicaciones{
    width: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: 550px) {
       padding: 0 20px;
    }
    &_top{
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        @media (max-width: 1140px) {
            flex-direction: column;
            margin-bottom: 50px;
        }
        // @media (max-width: 450px) {
        //     width: 200px;
        // }
        .texto{
            width: 250px;
            @media (min-width: 1600px) {
                width: 350px;
            }
            @media (max-width: 450px) {
                width: 200px;
            }
        }
        .mapa{
            width: 450px;
            @media (min-width: 1600px) {
                width: 530px;
            }
            @media (max-width: 450px) {
                width: 80%;
            }
        }
        &_filtrado{
            display: flex;
            flex-direction: column;
            width: 35%;
            align-items: center;
            @media (min-width: 1600px) {
                width: 30%;
            }
            @media (max-width: 1140px) {
                width: 80%;
            }
            @media (max-width: 550px) {
                width: 100%;
            }
            &__filtros{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin-bottom: 20px;
                @media (max-width: 1140px) {
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-bottom: 50px;
                    div{
                        width: 45%;
                        &:nth-child(1){
                            margin-right: 10px;
                        }
                        &:nth-child(2){
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            justify-content: flex-end;
                            p{
                                width: 100%;
                            }
                        }
                    }
                    .button__encontrar{
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;
                    }
                }
                // @media (max-width: 450px) {
                //    flex-wrap: wrap;
                // }

            }
            p{
                font-size: 20px;
                font-family: 'gill sans bold';
                margin-bottom: 15px;
                @media (min-width: 1600px) {
                   font-size: 30px;
                }
            }
            select{
                border: none;
                background-color: #e3e3e3;
                border-radius: 15px;
                width: 170px;
                font-size: 16px;
                padding: 5px;
                @media (min-width: 1600px) {
                    width: 180px;
                }
            }
            input{
                cursor: pointer;
                border: none;
                background-color: #e3e3e3;
                font-size: 16px;
                border-radius: 15px;
               width: 100px;
                height: 30px;
                @media (min-width: 1600px) {
                    width: 120px;
                }
                @media (max-width: 1140px) {
                    margin-top: 10px;
                }
                @media (max-width: 450px) {
                    margin-top: 10px;
                }
            }
            h3{
                color: #f20004;
                font-size: 30px;
                @media (min-width: 1600px) {
                    font-size: 40px;
                }
                @media (max-width: 1140px) {
                    // text-align: center;
                }
                @media (max-width: 450px) {
                    font-size: 25px;
                    // text-align: center;
                }
                span{
                    font-size: 35px;
                    font-family: 'gill sans bold';
                    @media (min-width: 1600px) {
                        font-size: 45px;
                     }
                }
            }
        }
    }
    &_bottom{
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 450px) {
        //    flex-direction: column;
        //    padding: 10px 0;
        }
        img{
            @media (max-width: 1140px) {
                width: 30%;
            }
            @media (max-width: 450px) {
                width: 140px;
            }
        }
        .momento{
            width: 500px;
            @media (min-width: 1600px) {
                width: 600px;
            }
            @media (max-width: 1140px) {
                width: 30%;
            }

            @media (max-width: 450px) {
                width: 50%;
                margin: 50px 0;
            }
        }
    }
}