.contenedor__port {
  width: 100%;
  height: auto;
  background-color: #f20004;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  @media (min-width: 1600px) {
    padding-top: 100px;
    height: 100vh;
  }
  .bottom__triangulo1 {
    position: absolute;
    width: 100%;
    z-index: 88;
  }
  .degradado {
    position: absolute;
    width: 100%;
  }
  &_top {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 60vh;
    @media (max-width: 900px) {
      flex-direction: column;
      justify-content: flex-end;
      height: 50vh;
    }
    &_slogan {
      width: 35%;
      height: 90%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      @media (max-width: 900px) {
        width: 100%;
        height: 50%;
        align-items: center;
      }
      @media (max-width: 550px) {
        height: 40%;
      }
      .esmomento {
        width: 60%;
        @media (min-width: 1600px) {
          width: 65%;
        }
        @media (max-width: 900px) {
          width: 45%;
        }
        @media (max-width: 550px) {
          width: 55%;
        }
      }
    }
    &_prototipo {
      width: 65%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      @media (max-width: 900px) {
        width: 100%;
        height: 50%;
      }
      @media (max-width: 550px) {
        height: 53%;
      }
      img {
        // width: 100%;
        object-position: top;
        object-fit: contain;
        height: 100%;
        @media (max-width: 900px) {
          height: 90%;
        }
        @media (max-width: 550px) {
          height: 90%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    // Modifiers
    &--project {
      overflow: hidden;
      @media (max-width: 1199px) {
        height: auto;
      }
    }
  }
  &_equipo {
    height: 40vh;
    background-color: white;
    display: flex;
    align-items: center;
    @media (max-width: 1250px) {
      width: 100%;
      height: 100%;
    }
    @media (max-width: 992px) {
      height: 100%;
    }
    @media (max-width: 550px) {
      height: 100%;
      flex-direction: column-reverse;
      justify-content: space-between;
    }
    .equipamiento {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 15px 30px;

      @media (max-width: 550px) {
        width: 100%;
        height: 40%;
        justify-content: space-around;
        flex-direction: row;
      }
      img {
        width: 400px;
        &:nth-child(1) {
          margin-bottom: 10px;
        }
        @media (max-width: 992px) {
          width: 240px;
        }
        @media (max-width: 550px) {
          width: 250px;
        }
      }
    }

    .amenidades {
      width: 800px;
      max-width: 100%;
      img {
        width: 230px;
        max-width: 100%;
        height: 100px;
        object-fit: contain;
        @media (max-width: 550px) {
          padding: 15px;
        }
      }
      div {
        display: flex;
        justify-content: center;
        gap: 15px;
      }
      & > div:first-child {
        grid-column: 1/-1;
      }
      & > div:nth-child(2) {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        div {
          flex: 1 0 auto;
        }
        img {
          max-width: none;
        }
      }
    }
  }
}
