.gracias{
    width: 100%;
    height: 100vh;
    background-color: #f20004;
    position: relative;
    h1{
        position: absolute;
        color: white;
        text-align: center;
        height: 90px;
        width: 70%;
        font-size: 40px;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        @media (max-width:880px) {
            width: 80%;
        }
        @media (max-width:550px) {
            width: 90%;
            font-size: 25px;
        }
    }
}