

.contenedor__portada{
    background-color: #f20004;
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: flex-end;
    position: relative;
    padding-top: 80px;
    @media (max-width: 900px) {
        height: 50vh;
    }
    .bottom__triangulo1{
        position: absolute;
        width: 100%;
        z-index: 88;
    }
    .cuadros{
        width: 40px;
        position: absolute;
        top: 40%;
        @media (max-width: 900px) {
            width: 20px;
        }
    }
    .cuadros2{
        width: 40px;
        position: absolute;
        top: 40%;
        right: 0;
        @media (max-width: 900px) {
            width: 20px;
        }
    }
    .degradado{
        position: absolute;
        width: 100%;
    }
    &_slogan{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .slogan{
            width: 30%;
            @media (min-width: 1600px) {
                width: 30%;
            }
            @media (max-width: 900px) {
                width: 70%;
            }
        }
    }
}
.contenedor__concepto{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    &_frase{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        @media (max-width: 900px) {
            margin-top: 35px;
        }
        &_borde{
            width: 100%;
            display: flex;
            justify-content: center;
            &_center{
                width: 80%;
                border: 2px solid #f20004;
                padding: 20px;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                @media (max-width: 900px) {
                    width: 90%;
                    padding: 10px 5px;

                }
                h3{
                    color: #f20004;
                }
                p{
                    font-size: 20px;
                    margin-left: 5px;
                    @media (max-width: 900px) {
                        font-size: 15px;
                    }
                }
            }
        }
        &_somos{
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 50px 0;
            @media (max-width: 900px) {
                padding: 20px 0;
            }
            &_center{
                width: 70%;
                display: flex;
                align-items: center;
                @media (max-width: 900px) {
                    width: 90%;
                }
                p{
                    font-size: 20px;
                    @media (max-width: 900px) {
                       font-size: 15px;
                    }
                }
            }
        }
    }
    &_mapa{
        // position: absolute;
        width: 100%;
        padding-left: 50px;
        display: flex;
        justify-content: flex-start;
        @media (min-width: 1600px) {
            padding-left: 150px;
        }
        @media (max-width: 900px) {
            padding: 20px;
        }
        .mapa{
            width: 80%;
            @media (min-width: 1600px) {
                width: auto;
            }
            @media (max-width: 520px) {
                width: 160%;
            }
        }
    }
    .tiempo{
        position: absolute;
        top: 50%;
        width: 280px;
        @media (min-width: 1600px) {
           width: 350px;
        }
        @media (max-width: 900px) {
           width: 170px;
           top: 55%;
        }
        @media (max-width: 520px) {
            width: 120px;
            top: 55%;
         }
    }
    .tiempo2{
        position: absolute;
        top: 70%;
        right: 5%;
        width: 280px;
        @media (min-width: 1600px) {
           width: 350px;
           right: 10%;
           top: 70%;
        }
        @media (max-width: 900px) {
            width: 170px;
            top: 70%;
            right: 5%;
         }
        @media (max-width: 520px) {
            width: 120px;
        }
    }

}
.contenedor__mision{
    background-color: #f20004;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 30px 50px;
    @media (max-width: 900px) {
        padding: 30px 0;
    }
    @media (max-width: 520px) {
        padding: 30px 20px;
    }
    .mision{
        width: 45%;
        padding-bottom: 30px;

        @media (max-width: 520px) {
            width: 100%;
        }
        p{
            color: #fff;
            font-size: 20px;
            @media (max-width: 900px) {
                font-size: 15px;
             }
        }
        div{
            display: flex;
            align-items: flex-start;
            justify-content: center;
            height: 70px;
            img{
                width: 45px;
                margin-right: 5px;
                @media (max-width: 520px) {
                    width: 30px;
                }
                
            }
            h1{
                color: #f9b833;
                @media (max-width: 520px) {
                    font-size: 20px;
                 }
            }
        }
    }
    .vision{
        width: 45%;
        padding-bottom: 30px;
        @media (max-width: 520px) {
            width: 100%;
        }
        p{
            color: #fff;
            font-size: 20px;
            @media (max-width: 900px) {
                font-size: 15px;
             }
            span{
                color: #f9b833;
            }
        }
        div{
            display: flex;
            align-items: flex-start;
            justify-content: center;
            height: 70px;
            img{
                width: 55px;
                margin-right: 5px;
                @media (max-width: 520px) {
                    width: 30px;
                }
            }
            h1{
                color: #f9b833;
                @media (max-width: 520px) {
                   font-size: 20px;
                }
            }
        }
    }
    .valores{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1{
            color: #f9b833;
            padding-bottom: 20px;
            @media (max-width: 520px) {
                font-size: 20px;
             }
        }
        .iconos{
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            height: 75px;
            @media (max-width: 520px) {
               flex-wrap: wrap;
               height: 100px;
               justify-content: space-between;
             }
            div{
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 100%;
                justify-content: space-between;
                @media (max-width: 520px) {
                    height: 47%;
                    width: 33%;
                 }
                img{
                    width: 40px;
                    @media (max-width: 520px) {
                        width: 25px;
                    }
                }
                h4{
                    color: #fff;
                    @media (max-width: 520px) {
                        font-size: 11px;
                     }
                }
            }
        }
    }
}