
.header{
    background-color: #f20004;
    width: 100%;
    height: 80px;
    @media (min-width: 1600px) {
        height: 100px;
    }
    padding: 15px 10px;
    position: fixed;
    z-index: 999;
    box-shadow: inset 0 0 50px -10px maroon;
    @media (max-width: 900px) {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
        padding: 5px 10px;
        height:73px;
        transition: height 1s ease;
    }
    &.opened{
        // @media (max-width: 900px) {
            height: 100vh;
            align-items: flex-start;
            transition: height 1s ease;
            nav{
                
                ul{
                    transform: translateY(120px);
                    height: 50%;
                    opacity: 1;
                    li{
                        ul{
                            top: -4px;
                            height: 0;
                            transition: height 0.5s ease;
                            li{
                                transition: opacity 0.5s ease;
                            }
                        }
                    }
                }
                .social{
                    display: flex;
                }
            }
        //}
    }
    &.menu-open span{
        background-color: transparent !important;
        &::before {
            top: 0 !important;
            transform: rotate(-45deg);
        }
        &::after {
            bottom: 0 !important;
            transform: rotate(45deg);
        }
    }
    .btn-toggle {
        width: 50px;
        height: 50px;
        display: none;
        margin-right: 0px !important;
        // background-color: black;
        cursor: pointer;
        position: relative;
        @media (max-width: 900px) {
            display: block;
        }
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            height: 3.8px;
            width: 60%;
            background-color: white;
            transition: width .5s;
            &::before, &::after {
                content: '';
                width: 100%;
                height: 100%;
                background-color:white;
                position: absolute;
                left: 0;
                transition: width .5s;
            }

            &::before {
                top: -8px;
            }
            &::after {
                bottom: -7px;
            }
            &::before, &::after {
                transition: all .3s;
            }
            .menu-open & {
                background-color: transparent;
                &::before {
                    top: 0;
                    transform: rotate(-45deg);
                }
                &::after {
                    bottom: 0;
                    transform: rotate(45deg);
                }
            }
        }
        // #{$links} {
        //     span {
        //         width: 80%;
        //         &::before, &::after {
        //             width: calc(100%);
        //         }
        //     }
        // }
    }
    nav{
        display: flex;
        width: 100%;
        height: 100%;
        @media (max-width: 900px) {
            align-items: flex-start;
        }

        .header_logo_movil{
            display: none;
            @media (max-width: 900px) {
                display: flex;
            }
            img{
                width: 200px;
                @media (max-width: 900px) {
                    width: 150px;
                    padding: 5px;
                }
            }
        }
        ul{
            width: 80%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            @media (max-width: 900px) {
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                width: 95%;
                align-items: flex-start;
                
                // padding-top: 50px;
                //transition: transform 1s ease;
                transform: translateY(-800px);
                opacity: 0;
                transition: transform 0.5s ease, opacity 0.2s ease;
            }
            li{
                list-style: none;
                cursor: pointer;
                color: #ffff;
                padding: 5px 5px;
                position: relative;
                transition: all 1s;
                a{
                    text-decoration: none;
                    color: white;
                }
                @media (max-width: 900px) {
                    order:0;
                    font-size: 30px;
                    padding-bottom: 70px;
                }
                .cuadros{
                    display: none;
                    @media (max-width: 900px) {
                        display: inline-block;
                        width: 5px;
                        margin-right: 5px;
                    }

                }
                &.header_logo{
                    background-color: transparent !important;
                    @media (max-width: 900px) {
                        display: none;
                    }

                }
                img{
                    width: 150px;
                    @media (min-width: 1600px) {
                        width: 180px;
                    }
                    
                }
                &.proyectos{
                    &:hover{
                        @media (max-width: 900px) {
                            padding-bottom: 10px;
                        }

                    }
                }
                ul{
                    // display: none;
                    // transform: translateY(-200px);
                    height: 0;
                    opacity: 0;
                    position: absolute;
                    background-color:white;
                    width: 370px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    left: 6px;
                    top: 45px;
                    //transition: transform 1s ease, opacity 2s ease;
                    padding: 0;
                    transition: height 1s ease, opacity 2s ease, padding 1s ease;
                    li{
                        color: #f20004;
                        opacity: 0;
                        padding-bottom: 5px;
                        padding: 0;
                        margin-left: 5px;
                        border-bottom: 2px solid transparent;
                        // width: 70%;
                        @media (max-width: 900px) {
                            border: none;
                            font-size: 15px;
                        }
                        a{
                            color: #f20004;
                        }
                        &:hover{
                            background-color: transparent;
                            border-radius: 0;
                            border-bottom: 2px solid #FAC50E;
                            @media (max-width: 900px) {
                                border: none;
                            }
                        }
                    }
                    @media (max-width: 900px) {
                        position: relative !important;
                        // height: 0 !important;
                        transform: translateY(0px) !important;
                        // background-color: white;
                        color: #393939;
                        width: auto;
                        margin-top: 10px;
                        transition: height 1s ease,opacity 2s ease;
                        li{
                            // color: #393939;
                            padding-bottom: 20px;
                            font-size: 15px;
                        }
                    }
                }
                &:hover{
                    background-color: #FAC50E;
                    border-radius: 15px;
                    @media (max-width: 900px) {
                        background-color: transparent;
                    }

                }
                &:hover > ul{
                    // transform: translateY(10px);
                    height: 133px;
                    opacity: 1;
                    padding: 5px;
                    @media (max-width: 900px) {
                        height: 170px;

                    }
                    li{
                        opacity: 1;
                    }
                }
            }
        }
        .social{
            width: 25%;
            display: flex;
            justify-content: start;
            @media (max-width: 900px) {
                position: absolute;
                bottom: 2%;
                justify-content: space-between;
                width: 85%;
                display: none;
            }
            p{
                color:#fff;
                @media (max-width: 450px) {
                    font-size: 20px;
                }
            }
            &__valle{
                width: 40%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                @media (max-width: 900px) {
                    width: 45%;
                    height: 60px;
                    justify-content: space-between;
                }
                &_icons{
                    display: flex;
                    justify-content: space-around;
                    width: 60%;
                    @media (max-width: 900px) {
                        width: 70%;
                    }
                    img{
                        width: 15px;
                        @media (max-width: 900px) {
                            width: 20px;
                        }
                        &:hover{
                            transform: scale(1.5);
                        }
                    }
                }
            }
            &__aguascalientes{
                width: 40%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                @media (max-width: 900px) {
                    width: 45%;
                    height: 60px;
                    justify-content: space-between;
                }
                &_icons{
                    display: flex;
                    justify-content: space-around;
                    width: 40%;
                    @media (max-width: 900px) {
                        width: 50%;
                    }
                    img{
                        width: 15px;
                        @media (max-width: 900px) {
                            width: 20px;
                        }
                        &:hover{
                            transform: scale(1.5);
                        }
                    }
                }
            }
        }
    }
}