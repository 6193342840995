.error{
    border: 1px solid red !important;
}
.errores{
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    ul{
        li{
            color: white;
            font-size: 13px;
        }
    }
}
.oculto{
    display: none;
}
label{
    color: red;
    font-family: "gill sans light";
}
.container__brochure{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .formulario{
        width: 50%;
        height: 85%;
        padding: 20px 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background-color:#f20004 ;
        @media (min-width: 1600px) {
            width: 35%;
            height: 75%;
        }
        @media (max-width: 1024px) {
            height: 55%;
            width: 85%;
        }
        @media (max-width: 550px) {
            width: 100%;
            height: 65%;
            min-height: 450px;
            padding: 20px;
        }
        h3{
            color: white;
            padding: 0 10px;
            font-size: 40px;
            text-align: center;
            // font-family: 'monserrat';
            font-family: 'gill sans bold';
            letter-spacing: 2px;
            @media (max-width: 450px) {
                font-size: 30px;
            }
            // font-family: "gotham-medium";
            // @media #{$xl-min} {
            //     font-size: 23px;
            // }
        }
        h5{
            color: white;
            text-align: center;
            font-size: 20px;
            font-family: 'gill sans light';
        }
        p{
            color: white;
        }
        a{
            text-decoration: none;
            color: white;
            padding: 15px;
            border: 1px solid white;
            transition: all 1s ease;
            // @media #{$xl-min} {
            //     font-size: 20px;
            // }
            // @media #{$mobile-max} {
            //     font-size: 15px;
            //     padding: 10px;
            // }
            &:hover{
                background-color: #f20004;
                color: white;
                transition: all 1s ease;
            }
        }
        form{
            width: 100%;
            height: 85%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
            .form_row{
                width: 100%;
                display: flex;
                justify-content: space-between;
                input[type="text"]{
                    height: 30px;
                    border: 1px solid gray;
                    width: 48%;
                    outline: none;
                    padding-left: 10px;
                    // color: $green;
                    // &::placeholder{
                    //     color: $green;
                    // }
                }
                input[type="email"]{
                    width: 100%;
                    height: 30px;
                    border: none;
                    outline: none;
                    padding-left: 10px;
                    // color: $green;
                    // &::placeholder{
                    //     color: $green;
                    // }
                }
            }
            .descargar{
                display: flex;
                padding: 10px 0;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                @media (min-height: 1600px) {
                    width: 100%;
                    justify-content: space-between;
                }
                @media (max-width: 450px) {
                    width: 100%;
                    height: 175px;
                    flex-direction: column;
                }
                p{
                    font-size: 12px;
                    margin-bottom: 10px;
                    @media (max-width: 450px) {
                        font-size: 10px;
                        width: 100%;
                    }
                }
                span{
                    cursor: pointer;
                    &:hover{
                        color: #f20004;
                    }
                }
                a{
                    cursor: pointer;
                }
            }
            .recaptcha{
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                div{
                    width: 100%;
                }
                @media (max-width: 450px) {
                    width: 100%;
                }
            }
            .button{
                width: 30%;
                @media (max-width: 450px) {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
    .salesForce{
        height: 85%;
        form{
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            height: 70%;
        }
        label{
            color: white;
        }
        input{
            height: 30px;
            border: none;
            outline: none;
            padding-left: 10px;
        }
        input[type='submit']{
            padding: 0;
            font-size: 16px;
            padding: 0 10px;
            background-color: #f20004;
            border: 1px solid white;
            color: white;
            cursor: pointer;
        }
        select{
            width: 100%;
            height: 30px;
            border: 1px solid rgb(226, 224, 224);
            outline: none;
        }
        .row{
            display: flex;
            flex-direction: column;
            width: 50%;

            p{
                font-size: 12px;
                margin-bottom: 20px;
                @media (max-width:550px) {
                    margin: 10px 0;
                }
                span{
                    cursor: pointer;
                    &:hover{
                        color: rgb(92, 91, 91);
                    }
                }
            }
            @media (max-width: 550px) {
                width: 90%;
            }
        }
    }
    .nomodal{
        width: 100%;
        height: 500px;
        background-color: white;
        align-items: center;
        margin: 50px 0 78px 0;
        h3{
            color: rgb(92, 91, 91);
            width: 70%;
            text-align: start;
            @media (max-width:1024px) {
                width: 90%;
            }
            @media (max-width:550px) {
                width: 95%;
                text-align: center;
            }
        }
        form{
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            width: 70%;
            column-gap: 50px;
            @media (max-width:1024px) {
                width: 90%;
            }
            @media (max-width:550px) {
                width: 95%;
                justify-content: center;
            }
        }
        label{
            color: black;
        }
        input{
            height: 30px;
            border: 1px solid rgb(226, 224, 224);
            outline: none;
            padding-left: 10px;
        }
        input[type='submit']{
            padding: 0;
            font-size: 16px;
            padding: 0 10px;
            background-color: #f20004;
            border: 1px solid white;
            color: white;
            cursor: pointer;
        }
        select{
            width: 100%;
            height: 30px;
            border: 1px solid rgb(226, 224, 224);
            outline: none;
        }
        .row{
            width: 45%;
            &:last-child{
                width: 80%;
                input{
                    width: 100px;
                }
            }
            @media (max-width:550px) {
                width: 80%;
            }
            p{
                color: rgb(92, 91, 91);
                font-size: 15px;
                @media (max-width:550px) {
                    font-size: 12px;
                }
                span{
                    cursor: pointer;
                    &:hover{
                        color: #f20004;
                    }
                }
            }
        }
    }
}