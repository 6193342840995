.contenedor__detalle{
    display: flex;
    width: 100%;
    height: 120vh;
    @media (min-width: 1600px) {
        height: 120vh;
    }
    @media (max-width: 900px) {
        height: 800px;
    }
    @media (max-width: 550px) {
        height: 160vh;
        flex-direction: column;
    }
    input{
        width: 80px;
        height: 30px;
        background-color: #0470b5;
        color: white;
        border: 1px solid transparent;
        border-radius: 15px;
        cursor: pointer;
        transition: background-color 1s;
        font-family: "gill sans medium";
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 550px) {
            font-size: 16px;
            padding-top: 4px;
        }
        &:hover{
            color:#0470b5;
            border: 1px solid #0470b5;
            background-color: white;
        }
    }
    a{
        width: 180px;
        height: 30px;
        text-decoration: none;
        cursor: pointer;
        transition: background-color 1s;
        font-family: "gill sans medium";
        font-size: 18px;
        background-color: #0470b5;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        @media (max-width: 550px) {
            font-size: 16px;
            padding-top: 4px;
        }
        &:hover{
            color:#0470b5;
            border: 1px solid #0470b5;
            background-color: white;
        }
    }
    &_fachada{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        @media (max-width: 550px) {
            width: 100%;
            height: 50%;
        }
        .imagen{
            width: 85%;
            height: 70%;
            display: flex;
            justify-content: flex-start;
            @media (min-width: 1600px) {
                width: 80%;
            }
            @media (max-width: 900px) {
                width: 95%;
                height: 65%;
            }
            div{
                width: 80%;
                padding-top: 20px;
                @media (min-width: 1600px) {
                    width: 75%;
                }
                @media (max-width: 900px) {
                    width: 100%;
                }
                @media (max-width: 550px) {
                    width: 95%;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: center;
                }
            }
        }
        .descripcion{
            height: 20%;
            width: 85%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            @media (min-width: 1600px) {
                width: 80%;
            }
            @media (max-width: 900px) {
                width: 95%;
            }
            div{
                width: 95%;
                @media (min-width: 1600px) {
                    width: 80%;
                }
                @media (max-width: 900px) {
                    width: 100%;
                }
                @media (max-width: 550px) {
                    width: 95%;
                    p{
                        font-size: 14px;
                    }
                }
            }
        }
        .brochure{
            height: 10%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            input{
                width: 150px;
            }
        }

    }
    &_planta{
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        @media (max-width: 550px) {
            width: 100%;
            height: 50%;
        }
        .imagen{
            width: 80%;
            height: 70%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            @media (min-width: 1600px) {
                width: 80%;
                height: 70%;
            }
            @media (max-width: 900px) {
                width: 95%;
                height: 75%;
            }
            div{
                width: 75%;
                height: 100%;
                padding-top: 20px;
                @media (max-width: 900px) {
                    width: 95%;
                }
                img{
                    width: 100%;
                    object-fit: contain;
                    height: 100%;
                    @media (max-width: 900px) {
                        // height: auto;
                    }
                }
            }

        }
        .opciones{
            width: 100%;
            height: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            div{
                width: 50%;
                display: flex;
                justify-content: space-evenly;
                align-items: flex-end;
                height: 100%;
                @media (max-width: 900px) {
                   width: 75%;
                }
                @media (max-width: 550px) {
                    align-items: center;
                }
                input{
                    background-color: #b3a172;
                    width: 100px;
                    &:hover{
                        border: 1px solid #b3a172;
                        color: #b3a172;
                        background-color: white;
                    }
                }
            }
        }
        .asesor{
            width: 100%;
            height: 10%;
            display: flex;
            justify-content: center;
            align-items: center;
            input{
                width: 180px;
            }
        }
    }
}