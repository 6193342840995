
.contenedor_detalleArticulo{
    width: 100%;
    padding: 50px 80px;
    position: relative;
    @media (min-width:1600px) {
        padding: 100px 130px;
    }
    @media (max-width:1024px) {
        padding: 40px 30px;
    }
    @media (max-width:550px) {
        padding: 85px 30px;
    }
    a{

        position: absolute;
        text-decoration: none;
        color: white;
        padding: 5px 10px;
        border-radius: 10px;
        right: 80px;
        bottom: 50px;
        @media (min-width:1600px) {
            right: 130px;
            bottom: 100px;
        }
        @media (max-width:1024px) {
            bottom: 20px;
            right: auto;
        }
        background-color: #f20004;
    }
    &_detalle{
        padding: 80px 0;
        // display: grid;
        // grid-template-columns: 1fr 1.2fr;
        // grid-template-rows: auto 500px auto;
        // @media (min-width:1600px) {
        //     grid-template-columns: 1fr 1fr;
        //     grid-template-rows: auto 650px auto;
        // }
        // @media (max-width:1024px) {
        //     grid-template-rows: auto 550px auto;
        // }
        @media (max-width:550px) {
            display: grid;
            padding-top: 20px;
            grid-template-columns: 1fr 1.2fr;
            grid-template-rows: auto auto auto;
        }
        .img_portada{
            // grid-column: auto/2;
            //grid-row: 1/3;
            width: 50%;
            float: left;
            padding-right: 20px;
            // @media (max-width:1024px) {
            //     grid-column: 1/2;
            //     grid-row: 2/4;
            // }
            @media (max-width:550px) {
                grid-column: 1/3;
                grid-row: 2/3;
                width: auto;
                float: none;
                padding: 0;
            }
            img{
                width: 100%;
                // height: 100%;
                object-fit: contain;
                @media (max-width:550px) {
                    // height: 100%;
                    // // object-fit: cover;
                    // max-height: 600px;
                    // max-width: 433px;
                }
            }
        }
        .articulo_titulo{
            // grid-column: 2/3;
            //grid-row: 1/2;
            padding-left: 20px;
            padding-bottom: 30px;
            // @media (max-width:1024px) {
            //     grid-column: 1/3;
            //     grid-row: 1/2;
            //     padding-left: 0;
            // }
            @media (max-width:550px) {
                grid-column: 1/3;
                grid-row: 1/2;
                padding-left: 0;
                padding-bottom: 30px;
            }
            h1{
                font-size: 40px;
                line-height: 35px;
                font-family: "gill sans bold";
                @media (min-width:1600px) {
                    font-size: 50px;
                    line-height: 40px;
                    
                }
                @media (max-width:1024px) {
                    font-size: 30px;
                    line-height: 25px;
                }
                @media (max-width:550px) {
                    font-size: 30px;
                    line-height: 25px;
                }
            }
            .fecha{
                margin: 10px 0;
                display: flex;
                justify-content: space-between;
                p{
                    color: #f20004;
                    font-family: "gill sans light";
                }
            }
        }
        .articulo_contenido{
            // grid-column: 2/3;
            grid-row: 2/4;
            padding-left: 20px;
            // @media (max-width:1024px) {
            //     grid-column: 2/3;
            //     grid-row: 2/4;
            // }
            @media (max-width:550px) {
                grid-column: 1/3;
                grid-row: 3/4;
                padding-left: 0;
                padding-top: 30px;
            }
            h2{
                color: #f20004;
                margin-bottom: 10px;
            }
            p{
                font-family: "gill sans light";
                @media (max-width:550px) {
                    font-size: 15px;
                }
            }
            a{
                color: #f20004;
                font-family: "gill sans light";
            }
        }
    }
}