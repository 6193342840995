.locationBlock {
  background-color: #ff312d;
  display: flex;
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
  }

  &__entranceImg {
    width: 50%;
    @media (max-width: 992px) {
      display: none;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: right;
    }
  }
  &__markerMap {
    width: 60%;
    display: flex;
    align-items: center;
    padding: 30px;

    @media (max-width: 992px) {
      justify-content: center;
      width: 100%;
    }
    .markerContainer {
      position: relative;
      width: 400px;
      height: 400px;
      z-index: 1;
      @media (min-width: 768px) and (max-width: 1450px) {
        display: none;
        transform: scale(70%) translateX(-20%);
      }
      @media (max-width: 767px) {
        display: none;
      }
      .markerIcon {
        height: 100%;
      }
      .people {
        height: 100%;
        object-fit: contain;
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(50%, 20%);
        height: 90%;
      }
    }

    .mapBlock {
      width: 100%;
      max-width: 100%;
      text-align: center;
      position: relative;

      &__mapImg {
        width: 650px;
        max-width: 100%;
        padding-bottom: 20px;
        object-fit: contain;
        @media (max-width: 992px) {
          width: 500px;
        }
        @media (max-width: 500px) {
          width: 350px;
        }
      }
      .locationText {
        background-color: #dc0e15;
        position: absolute;
        top: 0;
        border-radius: 10px;
        p {
          padding: 15px 16px 0;
          color: white;
          font-size: 46px;
          font-weight: 600;
          @media (max-width: 992px) {
            font-size: 30px;
          }
        }
      }
      &__logoAddress {
        display: flex;
        flex-direction: column;
        position: relative;
        color: white;
        max-width: 100%;
        width: 420px;
        margin: 0 auto;
        justify-content: center;
        padding-left: 70px;
        object-fit: contain;
        @media (min-width: 768px) and (max-width: 1450px) {
          padding-right: 20px;
        }
        @media (max-width: 992px) {
          width: 400px;
          padding-left: 80px;
        }
        .projectLogo {
          position: absolute;
          left: 0;
          top: 0;
          @media (max-width: 500px) {
            left: 30px;
          }
          img {
            width: 60px;
            @media (max-width: 992px) {
              width: 50px;
            }
            @media (max-width: 500px) {
              width: 40px;
            }
          }
        }
        p {
          text-align: left;
          font-size: 22px;
          @media (max-width: 992px) {
            font-size: 18px;
          }
          @media (max-width: 500px) {
            font-size: 16px;
          }
          span {
            display: block;
          }
        }
        .bottomLogos {
          color: white;
          padding-top: 40px;
          position: relative;

          .iconsBlock {
            .visitUsText {
              font-size: 18px;
              padding-bottom: 10px;
              color: yellow;
              @media (max-width: 992px) {
                font-size: 16px;
              }
            }
            .locationImgs {
              display: flex;
              gap: 50px;
              @media (max-width: 992px) {
                gap: 40px;
              }
              @media (max-width: 500px) {
                gap: 20px;
              }
              img {
                width: 45px;
                object-fit: contain;
              }
              .phrase {
                position: absolute;
                bottom: -30px;
                right: -30px;
                @media (max-width: 992px) {
                  bottom: -20px;
                  right: -15px;
                }
                @media (max-width: 500px) {
                  right: 60px;
                }
                img {
                  width: 150px;
                  @media (max-width: 992px) {
                    width: 130px;
                  }
                  @media (max-width: 500px) {
                    width: 100px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
