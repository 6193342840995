$min_desk : "1600px";
$max_tablet_pro : "1024px";
$max_tablet: "768px";
$max_movil : "450px";
.ocultar{
    display: none;
}
.contenido{
        width: 70%;
        height: 80%;
        padding: 50px;
        overflow-y: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background-color: white;
        z-index: 999;
        @media (min-width: $min_desk) {
            width: 70%;
            height: 80%;
        }
        @media (max-width: $max_tablet_pro) {
            width: 70%;
            height: 50%;
        }
        @media (max-width: $max_tablet) {
            width: 80%;
            height: 70%;
        }
        @media (max-width: $max_movil) {
            width: 90%;
            height: 80%;
            padding: 30px 10px;
        }
        h1{
            color: #DD0908;
            text-align: center;
            padding: 20px 0;
            font-family: 'gill sans bold';
        }
        h3{
            margin-bottom: 20px;
        }
        a{
            color: #DD0908;
            position: absolute;
            top: 20px;
            right: 30px;
            font-size: 25px;
        }
        p{
            color: #414141;
            font-family: 'gill sans medium';
            text-align: justify;
            margin-bottom: 10px;
        }
        .texto-aviso{
            padding-top: 50px;
            @media (max-width: $max_tablet_pro) {
                padding-top: 10px;
            }
        }
    }
